import {NgModule} from '@angular/core'
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core'

import {
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MAT_LUXON_DATE_FORMATS,
    LuxonDateAdapter
} from '@angular/material-luxon-adapter'
import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl} from "@angular/material/paginator";
import {CustomMatPaginatorIntl} from "./helpers/mat-paginator-cs_CZ";


@NgModule({
    imports: [
        MatSnackBarModule
    ],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
            }
        },
        // date
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'cs-CZ'
        },
        {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter
        },
        // date time
        {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false, firstDayOfWeek: 1}},
        // pagination
        {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: {
                pageSizeOptions: [10, 25, 50, 100],
                showFirstLastButtons: true
            }
        }
    ]
})
export class SharedModule {
}
